import ZH from './ZH';
import SPANISH from './Spanish';

const languageList = {
    ZH,
    SPANISH
};

export default function Tr(key){
    const list = languageList[window.ximiWebLanguage || 'SPANISH'];
    // const list = languageList[language];

    return list[key] || key;
}
