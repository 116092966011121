/* eslint-disable */
import React, {useEffect, useContext, Suspense, useMemo, useState, useCallback, Fragment, useRef} from 'react';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import {
    Header, Loading, Index, Footer, UserLoginReg, ShopCar,
    AllGoods, About, Category, Personal, Collect, GoodsDetail,
    Checkout, OrderDetail, ForgetPassword, ResetPassword
} from './component';
import {globalContext} from "@src/redux/reducer";
import styles from './../style/style.scss';
import {actions} from "@src/redux/actions";
import Req from "@src/util/request";
import {getUrlParams} from "@src/util/util";
import Tr from '@src/translate';
import {currencyList} from '@src/util/data';

export default function Root() {
    const {store: {storeInfo}, dispatch} = useContext(globalContext);
    const [scrollTop, setScrollTop] = useState(getScroollTop());
    const [acceptCookie, setAcceptCookie] = useState(localStorage.getItem('acceptCookie') || 0);
    const [renderRoute, setRenderRoute]  = useState(false);
    const [contentHeight, setContentHeight]  = useState(false);
    const toTop = useRef(null);

    const getCategory = useCallback(() => {
        Req({
            url: '/customer/v1/category/list',
            type: "POST"
        }, res => {
            console.log(res);
            if (res) {
                dispatch(actions.categoryData(res.data));
            }
        }, error => {
        });
    }, [dispatch]);

    const getStoreInfo = useCallback(() => {
        Req({
            url: '/customer/v1/store/detail',
            type: "POST"
        }, res => {
            if (res) {
                dispatch(actions.storeInfo(res.data))
            }
        }, error => {
        });
    }, []);

    const getThemeColor = useCallback((callBack) => {
        Req({
            url: '/customer/v1/color',
            type: "GET"
        }, res => {
            if (res) {
                if(res && !res.data) return ;
                let css = `html{ --themeColor: ${ res.data || "#FBD249" };}`,
                    head = document.getElementsByTagName('head')[0],
                    style = document.createElement('style');
                style.rel = 'stylesheet';
                if(style.styleSheet){
                    style.styleSheet.cssText = css;
                }else{
                    style.appendChild(document.createTextNode(css));
                }
                head.appendChild(style);
            }
        }, error => {
        });
    }, []);

    const getStoreList = useCallback(() => {
        Req({
            url: '/customer/v1/store/getStoreByDomain',
            type: "POST",
            data: {
                domain: location.hostname
            }
        }, res => {
            if (res) {
                window.storeGuid = res.data && res.data.storeGuid;
            }
            getCategory();
            getStoreInfo();
            getThemeColor();

            //获取语言设置
            Req({
                url: '/customer/v1/storeLang',
                type: "GET"
            }, res=>{
                if(res && res.data){
                    let language;
                    if(location.href.includes('localhost')){
                        dispatch(actions.language('ZH', true));
                        setRenderRoute(true);
                        return ;
                    }
                    if(res.data.langType === 2){
                        language = (navigator.language || navigator.browserLanguage || navigator.userLanguage || '').includes('zh') ? 'ZH' : 'SPANISH';
                    }else{
                        language = res.data.lang === 1 ? 'SPANISH' : 'ZH';
                    }
                    dispatch(actions.language(language, true))
                }else{
                    dispatch(actions.language( (navigator.language || navigator.browserLanguage || navigator.userLanguage || '').includes('zh') ? 'ZH' : 'SPANISH', true))
                }
                setRenderRoute(true);
            }, error=>{
                dispatch(actions.language((navigator.language || navigator.browserLanguage || navigator.userLanguage || '').includes('zh') ? 'ZH' : 'SPANISH', true));
                setRenderRoute(true);
            });
        }, error => {
            setRenderRoute(true);
            getCategory();
            getStoreInfo();
            getThemeColor();
        });
    }, []);

    const acceptCookieAction = ()=>{
        localStorage.setItem('acceptCookie', 1);
        setAcceptCookie(1);
    }

    useEffect(() => {
        dispatch(actions.screeInfo({
            width: document.body.clientWidth || document.documentElement.clientWidth
        }));
        window.onresize = () => {
            dispatch(actions.screeInfo({
                width: document.body.clientWidth || document.documentElement.clientWidth
            }))
        };
        window.onscroll = () => {
            setScrollTop(getScroollTop());
        };
        return () => {
            window.onresize = null;
            window.onscroll = null;
        }
    }, [scrollTop]);

    useEffect(() => {
        if(getStoreGuid()){
            setRenderRoute(true);
            window.storeGuid = getStoreGuid();
            getCategory();
            getStoreInfo();
            return ;
        }
        getStoreList();
    }, []);

    useEffect(()=>{
        window.gotoTop = ()=>toTop.current && toTop.current.click();
        if('onhashchange' in window){
            window.onhashchange = ()=>{
                window.gotoTop && window.gotoTop();
            };
        }
        return ()=>{
            window.onhashchange = null;
        }
    }, []);

    useEffect(()=>{
        if(renderRoute){
            const getContentHeight = ()=>{
                let doucumentHeight = window.innerHeight || (document.documentElement && document.documentElement.clientHeight) || document.body.clientHeight;
                let headerHeight = document.getElementById('header').getBoundingClientRect().height;
                let footerHeight = document.getElementById('footer').getBoundingClientRect().height;
                return doucumentHeight - headerHeight - footerHeight - 35;
            }
            setContentHeight(getContentHeight())

            window.onresize = ()=>{
                setContentHeight(getContentHeight())
            }
        }
    }, [renderRoute, setContentHeight])

    useEffect(()=>{
        window.getCurrency = ()=>{
            try {
                if(!storeInfo) return '€';
                if(!storeInfo.currency && (storeInfo.currency !== 0)) return '€';
                return currencyList[storeInfo.currency - 1].symbol;
            }catch (e){
                return '€';
            }
        }
    }, [storeInfo])

    return (
        <HashRouter getUserConfirmation={(message, callback) => {
            const allowTransition = window.confirm(message);
            callback(allowTransition);
        }}>
            <div>
                {
                    renderRoute ?
                        <Header/> : null
                }
                {
                    useMemo(() => <div className={styles.content} style={{ minHeight: contentHeight, padding: '1px 0' }}>
                        <Suspense fallback={<Loading/>}>
                            <Switch>
                                <Route path="/loding" exact component={Loading}/>
                                {
                                    renderRoute ?
                                        <Fragment>
                                            <Route path="/" exact component={Index}/>
                                            <Route path="/index" exact component={Index}/>
                                            <Route path="/login" exact component={UserLoginReg}/>
                                            <Route path="/forget" exact component={ForgetPassword}/>
                                            <Route path="/reset-newpassword" exact component={ResetPassword}/>
                                            <Route path="/shopcar" exact component={ShopCar}/>
                                            <Route path="/collect" exact component={Collect}/>
                                            <Route path="/goods/" exact component={AllGoods}/>
                                            <Route path="/goods/:name" exact component={AllGoods}/>
                                            <Route path="/detail/:id" exact component={GoodsDetail}/>
                                            <Route path="/category" exact component={Category}/>
                                            <Route path="/category/:id" exact component={Category}/>
                                            <Route path="/about/:type" exact component={About}/>
                                            <Route path="/order/detail/:id" exact component={OrderDetail}/>
                                            <Route path="/checkout" exact component={Checkout}/>
                                            <Route path="/personal" component={Personal}/>
                                        </Fragment>
                                        : null
                                }
                            </Switch>
                        </Suspense>
                    </div>, [renderRoute, contentHeight])
                }
                {
                    scrollTop > 0 ?
                        <i className="icon iconfont icon-previewleft-copy backToTop" onClick={() => {
                            document.body.scrollTop = document.documentElement.scrollTop = 0;
                        }}  ref={ toTop }> </i>
                        : null
                }
                {
                    renderRoute ?
                        <Footer/> : null
                }

                {
                    acceptCookie ? null :
                        <div className="cookie-tips">
                            <div className="tips-content">
                                <div className="title">{ Tr("add7") }</div>
                                <div className="flex">
                                    <div className="text-content">{ Tr("add8") }</div>
                                    <div className="btn" onClick={ acceptCookieAction }>{ Tr("add9") }</div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        </HashRouter>
    )
}

function getScroollTop() {
    if (document.documentElement && document.documentElement.scrollTop) {
        return document.documentElement.scrollTop;
    } else if (document.body) {
        return document.body.scrollTop;
    }
}

function getStoreGuid() {
    let guid = getUrlParams('storeGuid');
    let host = location.host;
    if(!guid){
        guid = sessionStorage.getItem('tempStoreGuid');
    }
    if(!host.includes('www') && host.split('.').length > 2){
        if(guid) sessionStorage.setItem('tempStoreGuid', guid);
    }else if(guid){
        if(guid) sessionStorage.setItem('tempStoreGuid', guid);
    }
    return guid;
}
