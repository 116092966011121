import React, {useState, useMemo, useCallback, useContext} from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ModalMask from '@src/component/common/ModalMask';
import CategoryMenu from '../../../Category/CategoryMenu';
import Tr from '@src/translate';
import {globalContext} from "@src/redux/reducer";
import './index.scss';

export default function Nav(){
    const [ showMenu, setShowMenu ] = useState(false);
    const [ showCategory, setShowCategory ] = useState(false);
    const { store:{ storeInfo, userInfo }, dispatch } = useContext(globalContext);
    const { pathname } = useLocation();

    const toAll = useCallback(()=>{
        if(!userInfo) {
            location.hash = '/login'
            return ;
        }
        sessionStorage.removeItem('allGoodsQueryParams');
        location.hash = '/goods';
    }, [userInfo]);

    const toCategory = ()=>{
        if(!userInfo) {
            location.hash = '/login'
            return ;
        }
        location.hash = '/category';
    }

    const Nav = useMemo(()=><ul className="nav-list" onClick={ ()=>{ setShowMenu(false) } }>
        <li className={ ['/', '/index'].includes(pathname) && "active" }><Link to="/index">{Tr("nav1")}</Link></li>
        <li className={ ['/goods/'].includes(pathname) && "active" }><a href="javascript:;" onClick={ toAll }>{ Tr("allGoods") }</a></li>
        <li className={ ['/category'].includes(pathname) && "active" }><a href="javascript:;" onClick={ toCategory }>{Tr("nav2")}</a></li>
        {
            storeInfo && storeInfo.showAboutMe ?
                <li className={ ['/about/about'].includes(pathname) && "active" }><Link to="/about/about">{Tr("common1")}</Link></li>
                : null
        }
    </ul>, [pathname, storeInfo]);

    return (
        <div className="layout header-layout menu-nav">
            <div className="menu-nav-container">
                {/*<div className="category-btn-container">*/}
                {/*    <div className="category-btn" onClick={ ()=>setShowCategory(!showCategory) }>*/}
                {/*        <span>{ Tr("nav3") }</span>*/}
                {/*    </div>*/}
                {/*    <div className="category-modal">*/}
                {/*        <CategoryMenu style={{ display: showCategory ? "block" : "none" }} closeModal={ ()=>setShowCategory(false) } />*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="nav">
                    { Nav }
                </div>
                <h3 className="nav-btn" onClick={ ()=>{ setShowMenu(!showMenu) } }>
                    <span>Menu</span>
                </h3>
            </div>
            <ModalMask className="modal-mask-nav" style={{ ...( showMenu ? {display: 'inline-block'} : { display: 'none' } ) }}>
                <div className="slide-menu">
                    <div className="close-slide-menu" onClick={ ()=>{ setShowMenu(!showMenu) } }>
                    </div>
                    { Nav }
                </div>
            </ModalMask>
        </div>
    )
}
