/**
 * Created by chenfei on 2018/2/5.
 */

import React from 'react';
import ReactDom from 'react-dom';
import Root from './component/Root';
import {ContextProvider} from "@src/redux/reducer";

if(location.href.includes('dev')){
    window.serverUrl = location.protocol + "//ddgapi.didigo.es";
}else{
    window.serverUrl = location.protocol + "//api2.didigo.es";
}
if(location.href.includes('localhost')){
    window.serverUrl = location.protocol + "//ddgapi.didigo.es";
}

let container = document.getElementById('container');
let node = <ContextProvider><Root /></ContextProvider>;



ReactDom.render(node, container);
