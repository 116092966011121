import React, { useReducer } from "react";
import { getTreeData } from '@src/util/util';

const globalContext = React.createContext();
window.ximiWebLanguage = localStorage.getItem('language') || 'SPANISH';
const initialState = {
    screeInfo: {},
    categorySelectedData: [],
    shopCarList: [],
    collectList: [],
    storeInfo: {},
    language: localStorage.getItem('language') || 'SPANISH',
    userInfo: JSON.parse(localStorage.getItem('USERINFO')) || null
};

function reducer(state, action){
    switch(action.type){
        case "USERINFO":
            return Object.assign({}, state, { userInfo: action.data });
        case "RESIZE":
            return Object.assign({}, state, { screeInfo: action.data });
        case "LANGUAGE":
            window.ximiWebLanguage = action.data;
            localStorage.setItem('language', action.data);
            if(!action.noRefresh){
                setTimeout(()=>{
                    location.reload();
                });
            }
            return Object.assign({}, state, { language: action.data });
        case "STOREINFO":
            document.title = action.data.name;
            let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.type = "image/x-icon";
            link.rel = "shortcut icon";
            link.href = action.data.logo && action.data.logo.url || '';
            document.getElementsByTagName("head")[0].appendChild(link);
            return Object.assign({}, state, { storeInfo: action.data });
        case "COLLECTLIST":
            return Object.assign({}, state, { collectList: action.data });
        case "SHOPCARLIST":
            return Object.assign({}, state, { shopCarList: action.data });
        case "HELPCENTER":
            return Object.assign({}, state, { helpCenterList: action.data });
        case "CATEGORYDATA":
            return Object.assign({}, state, {
                categoryData: getTreeData(action.data, 'upGuid', 'guid')
            });
        case "CATEGORYSELECTED":
            return Object.assign({}, state, { categorySelectedData: action.data });
    }
}

const ContextProvider = props=>{
    const [store, dispatch] = useReducer(reducer, initialState);
    return (
        <globalContext.Provider value={{ store, dispatch }}>
            { props.children }
        </globalContext.Provider>
    )
};

export { globalContext, ContextProvider }
