/* eslint-disable */

//组建用于集中导出自定义的所有组件 方便在别的组件中批量、多次调用

import React, { lazy } from 'react';

export Header from './common/Header';
export Footer from './common/Footer';
export Loading from './common/Loading';

export const Index = lazy(() => import('./Index'));
export const UserLoginReg = lazy(() => import('./UserLoginReg'));
export const ForgetPassword = lazy(() => import('./ForgetPassword'));
export const ResetPassword = lazy(() => import('./ResetPassword'));
export const ShopCar = lazy(() => import('./ShopCar'));
export const Checkout = lazy(() => import('./Checkout'));
export const Collect = lazy(() => import('./Collect'));
export const AllGoods = lazy(() => import('./AllGoods'));
export const GoodsDetail = lazy(() => import('./GoodsDetail'));
export const About = lazy(() => import('./About'));
export const Category = lazy(() => import('./Category'));
export const Personal = lazy(() => import('./Personal'));
export const OrderDetail = lazy(() => import('./Personal/OrderDetail'));
export const Account = lazy(() => import('./Personal/Account'));
export const Order = lazy(() => import('./Personal/Order'));
export const Address = lazy(() => import('./Personal/Address'));
