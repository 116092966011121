import Tr from '@src/translate';

export const currencyList = [
    {
        name: Tr('ximiadd266'),//欧元
        symbol: '€'
    },
    {
        name: Tr('ximiadd264'), //英镑
        symbol: '£'
    },
    {
        name: Tr('ximiadd265'),//元
        symbol: '¥'
    },
    {
        name: Tr('ximiadd267'),//美元
        symbol: '$'
    },
    {
        name: Tr('ximiadd268'),//第纳尔
        symbol: 'RSD'
    }
]
